import {DialogProps, showDialog} from 'components/Dialog'
import {t} from 'i18next'
import {ApiErrorDetail} from '@bakkt/api'
import {Icons, DialogText, Link, VBox} from '@bakkt/components'
import {isBrowser, isMobile} from 'react-device-detect'
import {EmbeddedWebState} from 'store'

interface ErrorDialogProps {
	partner?: EmbeddedWebState['partner']
	WrappedComponent?: React.ComponentClass<any> | (() => JSX.Element) | any
}

export const showErrorDialog = async ({
	title,
	message,
	saveText,
	partner,
	...props
}: ErrorDialogProps & Partial<DialogProps>) => {
	await showDialog({
		title: title || t('errors.modalTitle'),
		message,
		saveText:
			saveText ||
			(partner?.name
				? t('crypto.returnToPartnerButton', {partnerName: partner?.name})
				: t('crypto.returnButton')),
		onSave: () => {
			const defaultReturnUrl = 'https://www.bakkt.com'
			if (isMobile) {
				window.location.href = partner?.mobileReturnUrl || partner?.desktopReturnUrl || defaultReturnUrl
			} else if (isBrowser) {
				window.location.href = partner?.desktopReturnUrl || defaultReturnUrl
			} else {
				window.location.href = defaultReturnUrl
			}
		},
		icon: Icons.DialogWarning,
		hideCancel: true,
		preventHideDialog: true,
		saveButtonStyle: {
			marginBottom: '30px',
		},
		...props,
	})
}

export const showSuspendedErrorDialog = (partner?: EmbeddedWebState['partner']) => {
	const phone = partner?.contactInfo?.phone
	const email = partner?.contactInfo?.email

	showErrorDialog({
		title: t('errors.suspendedTitle'),
		WrappedComponent: () => (
			<VBox>
				<DialogText>{t('errors.suspendedMessage')}</DialogText>
				{phone && phone !== 'none' && <Link href={`tel:${phone}`}>{t('errors.contactByPhone', {phone})}</Link>}
				{email && email !== 'none' && (
					<Link href={`mailto:${email}`}>{t('errors.contactByEmail', {email})}</Link>
				)}
			</VBox>
		),
		partner,
	})
}

export const showOnboardingErrorDialog = (partner?: EmbeddedWebState['partner']) => {
	showErrorDialog({
		message: t('errors.onboardingError'),
		partner,
	})
}

export const showTransactionErrorDialog = (partner?: EmbeddedWebState['partner']) => {
	showErrorDialog({
		message: t('errors.transactionError'),
		partner,
	})
}

export const showTransactionCryptoErrorDialog = async (currency?: string) => {
	await showErrorDialog({
		message: t('errors.transactionCryptoError'),
		saveText: currency
			? t('crypto.returnToCurrencyOverviewButton', {currencyName: currency})
			: t('crypto.returnButton'),
		onSave: () => {},
		preventHideDialog: false,
	})
}
